import React from "react";

import Layout from "../components/Layout";
import SEO from "../components/seo";
import Header from "../components/Header";
import Link from "../components/Link";
import { URLS } from "../const/const";
import { Text } from "../style/Text";
import { Heading } from "../style/Heading";
import { Container, Flex, Wrapper } from "../style/Grid";

const NotFoundPage = () => (
  <Layout>
    <SEO title="404 Někde se asi stala chyba" />
    <Header variant="light" />
    <Wrapper>
      <Container>
        <Flex flexDirection={"column"} maxWidth={794}>
          <Heading as={"h1"} variant={"h1"}>
            404
          </Heading>
          <Heading as={"h2"} variant={"h2"} mb={4}>
            NĚKDE SE ASI STALA CHYBA
          </Heading>
          <Text mb={5} maxWidth={794}>
            Požadovanou stránku se nám nepodařilo najít. <br />
            Zkontrolujte prosím zadanou URL nebo{" "}
            <Link href={URLS.home} variant={"underline"}>
              pokračujte na úvodní stránku
            </Link>
            .
          </Text>
        </Flex>
      </Container>
    </Wrapper>
  </Layout>
);

export default NotFoundPage;
